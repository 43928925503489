import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Bar as Bar1 } from '../bar/Bar';
import {  
    VerticalGridLines, VerticalBarSeries, HorizontalGridLines, LineSeries, XAxis, XYPlot, YAxis
} from 'react-vis';

class ValueOfSales extends Component{
    constructor(){
        super();
        this.state = {
            sales: [],
            salesSum: [],
            percentTable: [],
            chartLine: [],
            chartBar: [],
            chartBarMonths: [],
            salesYear: [],
            type: 'raksa',
        }
    }

    componentDidMount(){
        try{
            this.fetchData()
            this.fetchDataBarChart()
            this.fetchDataLineChart()
            this.fetchDataPercent()
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Downloading Line Chart Data */
    fetchDataLineChart(){
        
        const token = localStorage.getItem('JWT-token');
        var year = localStorage.getItem('year-date');
        var month = localStorage.getItem('month-date');
        var monthTo = Number(month);
        monthTo += 1;
        var type = this.state.type
        try{
            Promise.all([
                fetch(process.env.REACT_APP_API + 'sales/perMonthChart'
                + `?dateFrom=2015-01-01`
                + `&dateTo=${year}-${monthTo}-01`
                + `&source=${type}`
                ,{
                    method:'GET',
                    mode: 'cors',
                    headers:{
                        'Accept':'application/json',
                        "Content-Type": "application/x-www-form-urlencoded",
                        'Authorization': `Bearer ${token}`
                    },
                })
                .then(res=>res.json())
                .then((res)=>{
                    this.setState({chartLine:res}); 
                })
                .catch((ex)=>{
                    console.log(ex)
                })
            ])
        }
        catch(ex){
            console.log(ex)
        }

    }
    /* Downloading Bar Chart Data */
    fetchDataBarChart(){
        const token = localStorage.getItem('JWT-token');
        var year = localStorage.getItem('year-date');
        var month = localStorage.getItem('month-date');
        var monthTo = Number(month);
        monthTo += 1;
        var type = this.state.type
        try{
            Promise.all([
                fetch(process.env.REACT_APP_API + 'sales/barChart'
                + `?source=${type}`
                + `&bars=6`
                + `&year=${year}`
                //+ `&dateFrom=2015-01-01`
                //+ `&dateTo=${year}-${monthTo}-01`
                ,{
                    method:'GET',
                    mode: 'cors',
                    headers:{
                        'Accept':'application/json',
                        "Content-Type": "application/x-www-form-urlencoded",
                        'Authorization': `Bearer ${token}`
                    },
                })
                .then(res=>res.json())
                .then((res)=>{
                    this.setState({chartBar:res.bars}); 
                    this.setState({chartBarMonths:res.months}); 
                })
                .catch((ex)=>{
                    console.log(ex)
                })
            ])
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Downloading data from API */
    fetchDataPercent(){
        const token = localStorage.getItem('JWT-token');
        var year = localStorage.getItem('year-date');
        var month = localStorage.getItem('month-date');
        var type = this.state.type
        try{
            Promise.all([
                fetch(process.env.REACT_APP_API + 'sales/perPrecent'
                + `?year=${year}`
                + `&month=${month}`
                + `&source=${type}`
                ,{
                    method:'GET',
                    mode: 'cors',
                    headers:{
                        'Accept':'application/json',
                        "Content-Type": "application/x-www-form-urlencoded",
                        'Authorization': `Bearer ${token}`
                    },
                })
                .then(res=>res.json())
                .then((res)=>{
                    this.setState({percentTable: res.monthResults});  
                    this.setState({percentTableChange: res.change});  
                    this.setState({percentTableSales: res.salesResult});  
                    this.setState({percentTableSalesPrev: res.salesResultPrev});  
                    console.log(res)
                })
                .catch((ex)=>{
                    console.log(ex)
                })
            ])
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Downloading Data from API */
    fetchData(){
        const token = localStorage.getItem('JWT-token');
        var year = localStorage.getItem('year-date');
        var month = localStorage.getItem('month-date');
        var monthTo = Number(month);
        monthTo += 1;
        var type = this.state.type
        try{
            Promise.all([
                fetch(process.env.REACT_APP_API + 'sales/perYear'
                + `?yearStart=2015`
                + `&yearStop=${year}`
                + `&source=${type}`
                ,{
                    method:'GET',
                    mode: 'cors',
                    headers:{
                        'Accept':'application/json',
                        "Content-Type": "application/x-www-form-urlencoded",
                        'Transfer-Encoding':'chunked',
                        'Authorization': `Bearer ${token}`
                    },
                })
                .then(res=>res.json())
                .then((res)=>{
                    this.setState({sales:res});  
                })
                .catch((ex)=>{
                    console.log(ex)
                })
            ])
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Table sales in month per Year */
    tableYear(){
        var sales = this.state.sales;
        sales = sales.reverse()
        return(
            sales.map((e)=>(
                <table className="table table-striped custom-table custom-table2 ">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" id="year-table-center">{e.year}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {e.months.map((e1)=>(
                            <tr scope="row">
                                <td>
                                    {e1.month}
                                </td>
                                <td>
                                    {e1.number}
                                </td>
                                <td>
                                    {e1.sales}
                                </td>
                            </tr>
                        ))}
                        <tr scope="row">
                            <td>
                                
                            </td>
                            <td>
                                Suma
                            </td>
                            <td>
                                {e.sum}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ))
        )
    }
    /* Line chart of data */
    chartLine(){
        var lineList = this.state.chartLine
        var lineMonthList = this.state.chartLine.map((e)=>(e.month));
        var months = ["sty","lut","mar","kwi","maj","cze","lip","sie","wrz","paz","lis",'gru']
    
        return (
            <XYPlot minWidth={500} width={1200} height={600} 
                colorType="category" 
                color='red'
            >
                <VerticalGridLines />
                <HorizontalGridLines />
                <YAxis />
                <XAxis 
                    tickFormat={v => months[lineMonthList[v]-1]}
                />
                <LineSeries
                    data={lineList.map((e, index)=>({
                        x: index,
                        y: Number(e.salesHint.slice(0,-5)),
                        style: {background: 'red', overflow: "hidden", stroke: 'red'}
                    }))}
                />
            </XYPlot>
        )
    }
    /* Bar chart of data */
    chartBar(){
        var barList = this.state.chartBar
        var barMonthList = this.state.chartBarMonths;
        var tableChart=[]
        for(var i = 0; i<=barList.length; i++){
            tableChart.push(<VerticalBarSeries data={barList[barList.length - i]} />)
        }
        return (
            <XYPlot minWidth={500} width={1200} height={600} >
                <VerticalGridLines />
                <HorizontalGridLines />
                <YAxis />
                <XAxis tickFormat={v => barMonthList[v-1]} />
                {tableChart}
            </XYPlot>
        )
    }
    /* Year to Year data */
    newYear(){
        var sales = this.state.sales;
        console.log(sales)
        return(
            sales.map((e)=>(
                e.year == localStorage.getItem('year-date') ?
                <table className="table table-striped custom-table custom-table2 ">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">{e.year}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {e.months.map((e1)=>(
                            <tr scope="row">
                                <td>
                                    {e1.month}
                                </td>
                                <td>
                                    {e1.number}
                                </td>
                                <td>
                                    {e1.sales}
                                </td>
                            </tr>
                        ))}
                        <tr scope="row">
                            <td>
                                
                            </td>
                            <td>
                                Suma
                            </td>
                            <td>
                                {e.sum}
                            </td>
                        </tr>
                    </tbody>
                </table>
                : 
                null
            ))
        )
    }
    /* Data month to month in year x and x-1 */
    percentTable(){
        var percentTable = this.state.percentTable
        var year = localStorage.getItem('year-date')
        var yearPrev = Number(year)-1
        var percentTableChange = this.state.percentTableChange 
        percentTableChange = String(percentTableChange)
        return(
            <div className="content salesMMyear">
                <div className="container sales-value">
                    <table className="table table-striped custom-table custom-table2 ">
                        <thead>
                            <tr>
                                <th scope="col">Miesiąc</th>
                                <th scope="col">Nr.</th>
                                <th scope="col">|</th>
                                <th scope="col">Sprzedaż {yearPrev}</th>
                                <th scope="col">|</th>
                                <th scope="col">Sprzedaż {year}</th>
                                <th scope="col">|</th>
                                <th scope="col">Zmiana %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {percentTable.map((e)=>(
                                <tr scope="row">
                                    <td>
                                        {e.month}
                                    </td>
                                    <td>
                                        {e.number}
                                    </td>
                                    <td>
                                        |
                                    </td>
                                    <td>
                                        {e.salesPrev}
                                    </td>
                                    <td>
                                        |
                                    </td>
                                    <td>
                                        {e.sales}
                                    </td>
                                    <td>
                                        |
                                    </td>
                                    <td className='change-countries' id={e.change == "0.00%" 
                                        ? "nullCountries" : e.change.slice(0,-1) < 0 
                                        ? "noCountries" : "yesCountries"}>
                                            <b>{e.change}</b>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            </tr>
                            <tr scope="row">
                                <td>
                                    <b>Suma okresu</b>
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    |
                                </td>
                                <td>
                                    <b>{this.state.percentTableSalesPrev}</b>
                                </td>
                                <td>
                                    |
                                </td>
                                <td>
                                    <b>{this.state.percentTableSales}</b>
                                </td>
                                <td>
                                    |
                                </td>
                                <td className='change-countries' id={percentTableChange == "0.00%" 
                                    ? "nullCountries" : percentTableChange.slice(0,-1) < 0 
                                    ? "noCountries" : "yesCountries"}>
                                        <b>{percentTableChange}</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    /* Content of page */
    content(){
        return(
            <div className="container-main col-xs-12 col-lg-12 col-md-12 col-sm-12">
                <Row>
                    <h2>Wartość sprzedaży</h2><br/>
                </Row>
                <Col className="row-main col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <Row>
                        <div className="content">
                            <div className="container">
                                <div className="content countries-to-countries">
                                    {this.tableYear()}
                                </div>
                            </div>
                        </div>
                    </Row>
                </Col>
                <Col className="row-main col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <Row>
                        <div className="content">
                            <h4 className='sales-value-title'>Wyniki miesiąc po miesiącu</h4>
                            <div className="container sales-value">
                                {this.chartLine()}
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="content">
                            <div className="container sales-value">
                                {this.chartBar()}
                            </div>
                        </div>
                    </Row>
                    <br/>
                    <Row>
                        {/* <Col className="row-main col-lg-0 col-md-0 col-sm-0 col-xs-12">
                            <div className="content">
                                <div className="container sales-value">
                                    {this.newYear()}
                                </div>
                            </div>
                        </Col> */}
                        <Col className="row-main col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            {this.percentTable()}
                        </Col>
                    </Row>
                </Col>
            </div>        
        )
    }    
    /* Main render */
    render(){
        if(localStorage.getItem("JWT-token") == null ||
           localStorage.getItem("JWT-token") == undefined ||
           localStorage.getItem("month-date") == null ||
           localStorage.getItem("month-date") == undefined ||
           localStorage.getItem("month-date") == '')
            {
                return window.location = '/wybierz-date'
            }
        else{
            return(
                <>
                    <Bar1/>
                    {this.content()}
                </>
            )
        }
    }
}
export default ValueOfSales;

