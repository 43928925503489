import React, {Component} from 'react';
import {Col, Form, Button, Row, Modal} from 'react-bootstrap';
import swal from 'sweetalert';
import {Bar as Bar1 } from './bar/Bar';

class DatePick extends Component{
    /* Submitting date and year into localstorage */
    handleSubmit(event){
        var date = event.target.date.value;
        console.log(date)
        var month = date.slice(5,7)
        var year = date.slice(0,4)

        localStorage.setItem('month-date', month)
        localStorage.setItem('year-date', year)

        window.location = '/strona-glowna'
    }
    /* Main content of page */
    content(){
        return(
            <div>
                <div className='container-datepicker'>
                    <Form onSubmit={this.handleSubmit}>
                        <h2>Wybierz datę</h2>
                        Dla której ma zostać wykonana analiza
                        <input                
                            type="month"
                            name="date"
                            className='month-year-test'
                            pattern='[0-9]{4}-[0-9]{2}'
                            placeholder='yyyy-mm'
                            required
                        /><br/>
                        <p class="text-date-picker">Wymagany format daty <b>yyyy-mm</b> (dla MacOS)</p>
                        <Button className="pagination-btn btn-main-datepicker" type="submit">
                            Wybierz
                        </Button>
                    </Form>
                </div>
            </div>
        )
    }
    /* Main function */
    render(){
        if(localStorage.getItem("JWT-token") == null ||
           localStorage.getItem("JWT-token") == undefined )
           {
             return window.location = '/'
           }
        if(localStorage.getItem('month-date') != null ||
           localStorage.getItem('month-date') != undefined){            
             return window.location = '/strona-glowna'
           }
        else{
            return(
                <>
                    <Bar1/>
                    {this.content()}
                </>
            )
        }
    }
}
export default DatePick;


