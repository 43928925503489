import {Component, React} from "react";
import {Form, Button, Row, Modal} from 'react-bootstrap';
import swal from 'sweetalert';
import {BarNoCanvas} from './bar/Bar';

class Login extends Component{
    
    handleSubmit(event){
        event.preventDefault();
        fetch(process.env.REACT_APP_API + 'authorize',{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                'User-Agent': 'PostmanRuntime/7.29.0',
                'Accept': '*/*',
            },
            body:JSON.stringify({
                identifier: event.target.login.value,
                password: event.target.password.value
            })
        })
        .then(res=>res.json())
        .then((res)=>{
            if(res.status == "succes"){
                localStorage.setItem("JWT-token", res.token)
                window.location = "/wybierz-date"
            }
            else{
                swal({
                    title: "Błąd",
                    text: "Błędna nazwa użytkownika lub hasło!",
                    icon: "warning",
                    dangerMode: true,
                });
            }
        })
        .catch((ex)=>{
            console.log(ex)
        })
    }

    render(){
        if(localStorage.getItem("JWT-token") != null){
            return window.location = "/strona-glowna"
        }
        else{
            return( 
                
                <div className="ContainerLogin">
                <BarNoCanvas/>
                <Modal.Dialog 
                    size="lg"
                    style={{marginTop: "100px"}}
                >                
                                
                    <div className="LoginPageMenu">
                        <Modal.Header>
                            <div className="LoginMenuTitleLeft">
                                <h1>Panel Logowania do Analizy Generalnej</h1>
                            </div>
                            <div className="LoginMenuTitleRight">
        
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="LoginMenuTitleBottom col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                    
                                <Form size="lg" onSubmit={this.handleSubmit}>
                                    <h3>Zaloguj się</h3>
                                    <Row className="mb-6"  size="lg">
                                        <Form.Group controlId="formGridLogin">
                                            <Form.Label>Nazwa użytkownika</Form.Label>
                                            <Form.Control 
                                                type="login"
                                                name="login" 
                                                placeholder="wprowadź nazwę użytkownika" 
                                                required/>
                                        </Form.Group>
                                        <Form.Group controlId="formGridPassword" style={{marginTop: '15px'}}>
                                            <Form.Label>Hasło</Form.Label>
                                            <Form.Control 
                                                type="password" 
                                                name="password" 
                                                placeholder="wprowadź hasło" 
                                                required/>
                                        </Form.Group>
                                    </Row>
                                        <br/>
                                        <Button className="ButtonLogin" 
                                                variant="primary"
                                                type="submit">
                                            Zaloguj
                                        </Button>
                                    </Form>
                                </div>
                            </Modal.Body>
                        </div>
                    </Modal.Dialog>               
                </div>
                )
        }

    }
}
export default Login;


