import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from './Login';
import Home from './Home.js';
import CRM from './crm/CRM';
import QJUTSU from './qjutsu/QJUTSU';
import Countries from './countries/countries';
import Products from './products/Products';
import Productsytoy from './products/Productsytoy';
import ValueOfSales from './ValueOfSales/ValueOfSales';
import DatePick from './DatePick';

function Routers() {
  return (
    <Router>
      <Fragment>
        <Routes>
          {/* All users */}
          <Route exact path='/' element={<Login/>}/>
        
          {/* Logged user */}
          <Route exact path='/wybierz-date' element={<DatePick/>}/>
          <Route exact path='/strona-glowna' element={<Home/>}/>
          <Route exact path='/CRM' element={<CRM/>}/>
          <Route exact path='/QJUTSU' element={<QJUTSU/>}/>
          <Route exact path='/kraje' element={<Countries/>}/>
          <Route exact path='/produkty' element={<Products/>}/>
          <Route exact path='/produkty-ytoy' element={<Productsytoy/>}/>
          <Route exact path='/wartosc-sprzedazy' element={<ValueOfSales/>}/>
        </Routes>
      </Fragment>
    </Router>
  );
}

export default Routers;
