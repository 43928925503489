import React from 'react';
import {Button} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export function Zoom(){
    return(
        <>
            <Button id="resize-body" onClick={ZoomUp}><Icon.ArrowsAngleExpand/> Powiększ</Button>
            <Button id="resize-body" onClick={ZoomDown}><Icon.ArrowsAngleContract/> Zmniejsz</Button>
        </>
    )
}
function ZoomUp(){
    document.body.style.zoom = "100%";
}
function ZoomDown(){
    document.body.style.zoom = "80%";
}