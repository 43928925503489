import React,{Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import { FunctionLogo, FunctionLogoNoHref } from './Logo';
import { Canvas } from './Canvas';
import '../styles/index.css';
import { Zoom } from './Zoom';


export class Bar extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="main-menu-bar">
                <Row>
                    <Col xs={12} sm={7} md={7} lg={7}>
                        {FunctionLogo()}
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                        {Zoom()}
                    </Col>
                    <Col xs={12} sm={1} md={1} lg={1}>
                        {Canvas()}
                    </Col>
                </Row>
                
            </div>
            

        );
    }
}


export class BarNoCanvas extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="main-menu-bar">
                <Row>
                    <Col xs={12} sm={4} md={4} lg={4}>
                        {FunctionLogoNoHref()}
                    </Col>
                    <Col xs={12} sm={7} md={7} lg={7}>
                        {Zoom()}
                    </Col>
                    <Col xs={12} sm={1} md={1} lg={1}>
                        {null}
                    </Col>
                </Row>
                
            </div>
            

        );
    }
}