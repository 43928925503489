import React from 'react';
import {Navbar, Nav, Container, Offcanvas} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ChangeDate } from './ChangeDate';
import {Logout} from './Logout';


export function Canvas(){
    var type = <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link id="CanvasAnimationMenu" href="/strona-glowna"><Icon.House/> Strona główna (TOTAL)</Nav.Link>
                    <Nav.Link id="CanvasAnimationMenu" href="/qjutsu"><Icon.DropletFill/> QJUTSU</Nav.Link>
                    <Nav.Link id="CanvasAnimationMenu" href="/crm"><Icon.PersonFill/> CRM</Nav.Link>
                    <Nav.Link id="CanvasAnimationMenu" href="/kraje"><Icon.MapFill/> Kraje y to y</Nav.Link>
                    <Nav.Link id="CanvasAnimationMenu" href="/produkty"><Icon.BoxSeam/> Produkty</Nav.Link>
                    <Nav.Link id="CanvasAnimationMenu" href="/produkty-ytoy"><Icon.Diagram3Fill/> Produkty y to y</Nav.Link>
                    <Nav.Link id="CanvasAnimationMenu" href="/wartosc-sprzedazy"><Icon.CurrencyEuro/> Wartość sprzedaży</Nav.Link>
                    
                    <Nav.Link id="CanvasAnimationMenu" onClick={ChangeDate}><Icon.Calendar2Month/> Zmień datę</Nav.Link>
                    <Nav.Link id="CanvasAnimationMenu" onClick={Logout}><Icon.BoxArrowInLeft/> Wyloguj</Nav.Link>
                </Nav>;
    return(
        <>
        <Navbar expand={false}>
            <Container fluid>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" id="canvas-button"/>
                        <Navbar.Offcanvas
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNa vbarLabel"
                            placement="end"
                            color="dark"
                            bg="dark"
                        >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className="navBarTitle" id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {type}
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
            </Container>
        </Navbar>
        </>     
    )
}