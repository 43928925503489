import React, {Component} from 'react';
import {Col, Button, Row} from 'react-bootstrap';
import {Bar} from '../bar/Bar';

class CRM extends Component{
    constructor(){
        super();
        this.state = {
            CRM: [],
            CRMMonth: [],
            pageCRM: 0,
            counterCRM: 0,
            limit: 10,
            type: 'raksa',
        }
    }

    componentDidMount(){
        try{
            this.fetchData(0)
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Downlading data function from API */
    fetchData(page){
        var type = this.state.type;
        try{
            const token = localStorage.getItem('JWT-token');
            var year = localStorage.getItem('year-date');
            var month = localStorage.getItem('month-date');
            var monthTo = Number(month);
            monthTo += 1;
            fetch(process.env.REACT_APP_API + `crm`
            + `?source=${type}`
            + `&dateFrom=2015-01-01`
            + `&dateTo=${year}-${monthTo}-01`
            + `&limit=${this.state.limit}`
            + `&page=${page}`
            ,{
                method:'GET',
                mode: 'cors',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(res=>res.json())
            .then((res)=>{
                this.setState({CRM:res.result, CRMMonth: res.headerResults});
            })
        }
        catch(ex){console.log(ex)}
    }
    /* Increment Pageing function */
    increment() {
        this.setState((prevState) => {
            let prev = prevState.pageCRM;
            let counterPrev = prevState.counterCRM;
            prev = prev + 10;
            counterPrev = counterPrev + 1;
            try{this.fetchData(prev)}catch(ex){return("Brak danych.")}
            return { pageCRM: prev, counterCRM: counterPrev};
        })
    }
    /* Decrement Pageing function */
    decrement() {
        this.setState((prevState) => {
            let prev = prevState.pageCRM;
            let counterPrev = prevState.counterCRM;
            prev = prev - 10;
            counterPrev = counterPrev - 1;
            if(prev < 0) prev=0;
            if(counterPrev < 0) counterPrev=0;
            try{this.fetchData(prev)}catch(ex){return("Brak danych.")}
            return { pageCRM: prev, counterCRM: counterPrev };
        })
    }
    /* Render Table CRM */
    renderTableCRM(type){
        var data = this.state.CRM;
        var monthTotal = this.state.CRMMonth;

        var monthList = data.map((e)=>(e.results.map((res) => (res.column != "sum" ? res.column : null)).filter(elem => elem != null)));
        var months = monthList[0]

        if(type=="crm"){
        return(
            <table className="table table-striped custom-table CRM-client-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <thead>
                    <tr>
                        <th className="headcol">
                            Klient
                        </th>
                        {months != null ? months.map((e, index)=>{
                            return(
                                <td key={index}>
                                    {e} |
                                </td>
                            )
                        }) : ''}
                        <td  className="long">
                        </td>
                        <td  className="long">
                            <b>Suma</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((client) => (
                        <tr>
                            <th className="headcol">
                                {client.name}
                            </th>
                            {client.results.map((res) => (     
                                <td className="long">
                                    {res.result} {res.result != null ? <span>zł</span> : null}
                                </td>
                            ))}
                            
                            <td className="long">
                                {client.results.map((res)=>(<b>{res.sum}</b>))} zł
                            </td>
                           
                        </tr>
                     ))}

                </tbody>
            </table>
        )
        }

        if(type=="sum"){
        return(
            <table className="table table-striped custom-table CRM-client-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <thead>
                    <tr>
                        <th className="headcol" id="monthCRMTotal">
                            Sumy miesięczne CRM
                        </th>
                        {months != null ? months.map((e, index)=>{
                            return(
                                <td key={index}>
                                    {e} |
                                </td>
                            )
                        }) : ''}
                        <td  className="long">
                        </td>
                        <td  className="long">
                            <b>--------</b>
                        </td>
                    </tr>
                </thead>            
                <tbody>
                    <tr>
                        <th className="headcol">
                            Suma miesięczna
                        </th>
                        {monthTotal.map((month) => (
                            <td className="long">
                                {month.result} zł
                            </td>
                        ))}
                    </tr>

                </tbody>
            </table>
        )
        }
    }
    /* Main content function */
    content(){
        return(
            <div className="container-main col-xs-12 col-lg-12 col-md-12 col-sm-12">
                <Col className="row-main col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <Row>   
                        <div className="container CRM-client-table-new" id="Select1">
                            <h2>CRM</h2><br/>
                            <div className="table-responsive">
                                {this.renderTableCRM("crm")}
                                    <br/>
                                {this.renderTableCRM("sum")}
                            </div>
                        </div>

                        <div className='btn-crm'>
                            <Button 
                                onClick={this.decrement.bind(this)} 
                                className='pagination-btn'
                            >
                                Poprzednia
                            </Button> 
                            <span className='pagination-main'>
                                Strona {this.state.counterCRM+1}. 
                            </span>
                            <Button 
                                onClick={this.increment.bind(this)} 
                                className='pagination-btn'
                            >
                                Następna
                            </Button>
                        </div>
                   </Row>
                </Col>
            </div>
        )
    }    
    /* Main */
    render(){
        if(localStorage.getItem("JWT-token") == null ||
           localStorage.getItem("JWT-token") == undefined ||
           localStorage.getItem("month-date") == null ||
           localStorage.getItem("month-date") == undefined ||
           localStorage.getItem("month-date") == '')
           {
                return window.location = '/wybierz-date'
           }
        else{
            return(
                <>
                    <Bar/>
                    {this.content()}
                </>
            )
        }
    }
}
export default CRM;