import React, {Component} from 'react';
import {Container, Col, Form, Button, Row, Modal} from 'react-bootstrap';
import swal from 'sweetalert';
import {Bar as Bar1 } from '../bar/Bar';
import $ from 'jquery';

class Countries extends Component{
    constructor(){
        super();
        this.state = {
            products: [],
            type: 'raksa',
        }
    }

    componentDidMount(){
        try{
            this.fetchData()
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Downlading data function from API */
    fetchData(){
        const token = localStorage.getItem('JWT-token');
        var year = localStorage.getItem('year-date');
        var month = localStorage.getItem('month-date');
        var type = this.state.type
        try{
            fetch(process.env.REACT_APP_API + 'product/yy'
            + `?year=${year}`
            + `&month=${month}`
            //+ `&source=${type}`
            ,{
                method:'GET',
                mode: 'cors',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(res=>res.json())
            .then((res)=>{
                this.setState({products:res});  
                console.log(res)
            })
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Render Table of Products */
    renderProductsTable(){
        var productsTable = this.state.products;
        return(
            <Row>
                <div className="content countries-to-countries">
                    <table className="table table-striped custom-table custom-table2 ">
                        <thead>
                            <tr>
                                <th scope="col">Produkty</th>
                                <th scope="col">2021</th>
                                <th scope="col">2022</th>
                                <th scope="col">Zmiana %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productsTable.map((e)=>(
                            <tr scope="row">
                                <td>
                                    {e.name}
                                </td>
                                <td>
                                    {e.prevYearResult}
                                </td>
                                <td>
                                    {e.yearResult}
                                </td>
                                <td className='change-countries' id={e.percent == "0.00%" 
                                ? "nullCountries" : e.percent.slice(0,-1) < 0 
                                ? "noCountries" : "yesCountries"}>
                                    <b>{e.percent}</b>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Row>
        )
    }
    /* Main content functiom */
    content(){
        return(
            <div className="container-main col-xs-12 col-lg-12 col-md-12 col-sm-12">
                <Row>
                    <h2>Produkty y to y</h2><br/>
                </Row>
                <Col className="row-main col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    {this.renderProductsTable()}
                </Col>
                <Col className="row-main col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <Row>
                        <img src="https://sklep.soft99.eu/1060-large_default/set-premium-light-z-kuferkiem.jpg"></img>
                    </Row>
                    <Row>
                        <img src="https://sklep.soft99.eu/1044-large_default/glaco-mega-set.jpg"></img>
                    </Row>
                    <Row>
                        <img src="https://sklep.soft99.eu/1063-home_default/fabric-kit.jpg"></img>
                    </Row>
                    <Row>
                        <img src="https://sklep.soft99.eu/1147-home_default/set-basic-white.jpg"></img>
                    </Row>
                </Col>
            </div>
        )
    }    
    /* Main Function */
    render(){
        if(localStorage.getItem("JWT-token") == null ||
           localStorage.getItem("JWT-token") == undefined ||
           localStorage.getItem("month-date") == null ||
           localStorage.getItem("month-date") == undefined ||
           localStorage.getItem("month-date") == '')
           {
                return window.location = '/wybierz-date'
           }
        else{
            return(
                <>
                    <Bar1/>
                    {this.content()}
                </>
            )
        }
    }
}
export default Countries;