import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Bar as Bar1 } from '../bar/Bar';
import $ from 'jquery';
import europe from '../styles/img/europe.png';

class Countries extends Component{
    constructor(){
        super();
        this.state = {
            countries: [],
            type: 'raksa',
        }
    }

    componentDidMount(){
        try{
            this.fetchData()
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Downlading data function from API */
    fetchData(){
        var type = this.state.type
        const token = localStorage.getItem('JWT-token');
        var year = localStorage.getItem('year-date');
        var month = localStorage.getItem('month-date');
        try{
            fetch(process.env.REACT_APP_API + 'country'
            + `?year=${year}`
            + `&month=${month}`
            + `&source=${type}`
            ,{
                method:'GET',
                mode: 'cors',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(res=>res.json())
            .then((res)=>{
                this.setState({countries:res});  
            })
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Render table of countries */
    renderTableCountries(){
        var countriesTable = this.state.countries;
        return(
            <Row>
                <div className="content countries-to-countries">
                    <table className="table table-striped custom-table custom-table2 ">
                        <thead>
                        
                            <tr>
                                <th scope="col">Kraj</th>
                                <th scope="col">2021</th>
                                <th scope="col">2022</th>
                                <th scope="col">Zmiana %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {countriesTable.map((e)=>(       
                                <tr scope="row">
                                    <td>
                                        {e.country}
                                    </td>
                                    <td>
                                        {e.prevYearResult}
                                    </td>
                                    <td>
                                        {e.yearResult}
                                    </td>
                                    <td className='change-countries' id={e.percent == "0.00%" 
                                    ? "nullCountries" : e.percent.slice(0,-1) < 0 
                                    ? "noCountries" : "yesCountries"}>
                                        <b>{e.percent}</b>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Row>
        )
    }
    /* Main content function */
    content(){
        return(
            <div className="container-main col-xs-12 col-lg-12 col-md-12 col-sm-12">
                <Row>
                    <h2>Kraje y to y</h2><br/>
                </Row>
                <Col className="row-main col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    {this.renderTableCountries()}
                </Col>
                <Col className="row-main col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <Row>
                        <img src={europe}></img>
                    </Row>
                </Col>
            </div>
            
        )
    }    
    /* Main */
    render(){
        if(localStorage.getItem("JWT-token") == null ||
           localStorage.getItem("JWT-token") == undefined ||
           localStorage.getItem("month-date") == null ||
           localStorage.getItem("month-date") == undefined ||
           localStorage.getItem("month-date") == '')
           {
            return window.location = '/wybierz-date'
           }
        else{
            return(
                <>
                    <Bar1/>
                    {this.content()}
                </>
            )
        }
    }
}
export default Countries;
