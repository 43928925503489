import React, {Component} from 'react';
import {Col, Form, Button, Row} from 'react-bootstrap';
import {Bar as Bar1 } from './bar/Bar';
import {  
    VerticalGridLines, HorizontalBarSeries, HorizontalGridLines, XAxis, XYPlot, YAxis
} from 'react-vis';

class Home extends Component{
    constructor(){
        super();
        this.state = {
            dataCountry: [],
            dataRaks: [],
            dataPresta: [],
            dataAllegro: [],
            counterRaks: 0,
            counterPresta: 0,
            counterAllegro: 0,
            pageRaks: 0,
            pagePresta: 0,
            pageAllegro: 0,
            page: 0,
            limit: 10,
            type: 'raksa',
            orderBy: 'sales',
            order: 'desc'
        }
    }

    componentDidMount(){
        try{
            this.fetchData("presta", 0)
            this.fetchData("raksa", 0)
            this.fetchData("allegro", 0)
            this.fetchData("country", 0)
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Downlading data function from API */
    fetchData(source, page){
        const token = localStorage.getItem('JWT-token');
        var month = localStorage.getItem('month-date');
        var year = localStorage.getItem('year-date');
        var monthTo = Number(month);
        monthTo += 1;
        var type = this.state.type
        var orderBy = this.state.orderBy
        var order = this.state.order
        try{
            if(source == "country"){
                fetch(process.env.REACT_APP_API+'general/country'
                + `?dateFrom=${year}-${month}-01`
                + `&dateTo=${year}-${monthTo}-01`
                + `&source=${type}`
                + `&orderBy=${orderBy}&order=${order}`
                ,{
                    method:'GET',
                    mode: 'cors',
                    headers:{
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                })
                .then(res=>res.json())
                .then((res)=>{
                    this.setState({dataCountry:res});  
                })
                .catch((ex)=>(
                    console.log(ex)
                ))
            }
            else{
                fetch(process.env.REACT_APP_API + 'general?source=' + source 
                + `&dateFrom=${year}-${month}-01`
                + `&dateTo=${year}-${monthTo}-01`
                + `&limit=${this.state.limit}`
                + `&page=${page}`
                ,{
                    method:'GET',
                    mode: 'cors',
                    headers:{
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                })
                .then(res=>res.json())
                .then((res)=>{
                    switch(source){
                        case "raksa":
                            this.setState({dataRaks:res});  
                            break;
                        case "presta":
                            this.setState({dataPresta:res});  
                            break;
                        case "allegro":
                            this.setState({dataAllegro:res});  
                            break;
                    }
                })
                .catch((ex)=>(
                    console.log(ex)
                ))
            }
        }
        catch(ex){
            console.log(ex)
        }
    }
    /* Increment Pageing function */
    increment(type) {
        if(type=="raks"){
            this.setState((prevState) => {
                let prev = prevState.pageRaks;
                let counterPrev = prevState.counterRaks;
                prev = prev + 10;
                counterPrev = counterPrev + 1;
                this.fetchData("raksa", prev) 
                return { pageRaks: prev, counterRaks: counterPrev};
            })
                
        }
        if(type=="presta"){
            this.setState((prevState) => {
                let prev = prevState.pagePresta;
                let counterPrev = prevState.counterPresta;
                prev = prev + 10;
                counterPrev = counterPrev + 1;
                this.fetchData("presta", prev)
                return { pagePresta: prev, counterPresta: counterPrev };
            })
            
        }
        if(type=="allegro"){
            this.setState((prevState) => {
                let prev = prevState.pageAllegro;
                let counterPrev = prevState.counterAllegro;
                prev = prev + 10;
                counterPrev = counterPrev + 1;
                this.fetchData("allegro", prev)
                return { pageAllegro: prev, counterAllegro: counterPrev };
            })
           
        }
    }
    /* Decrement Pageing function */
    decrement(type) {
        if(type=="raks"){
            this.setState((prevState) => {
                let prev = prevState.pageRaks;
                let counterPrev = prevState.counterRaks;
                prev = prev - 10;
                counterPrev = counterPrev - 1;
                if(prev < 0) prev=0;
                if(counterPrev < 0) counterPrev=0;
                this.fetchData("raksa", prev)
                return { pageRaks: prev, counterRaks: counterPrev };
            })
           
        }
        if(type=="presta"){
            this.setState((prevState) => {
                let prev = prevState.pagePresta;
                let counterPrev = prevState.counterPresta;
                prev = prev - 10;
                counterPrev = counterPrev - 1;
                if(prev < 0) prev=0;
                if(counterPrev < 0) counterPrev=0;
                this.fetchData("presta", prev)
                return { pagePresta: prev, counterPresta: counterPrev };
            })
           
        }
        if(type=="allegro"){
            this.setState((prevState) => {
                let prev = prevState.pageAllegro;
                let counterPrev = prevState.counterPresta;
                prev = prev - 10;
                counterPrev = counterPrev - 1;
                if(prev < 0) prev=0;
                if(counterPrev < 0) counterPrev=0;
                this.fetchData("allegro", prev)
                return { pageAllegro: prev, counterAllegro: counterPrev };
            })
           
        }
    }
    /* Render buttons function */
    renderButtons = (type) => {
        var pageNumberRaks = this.state.counterRaks + 1;
        var pageNumberPresta = this.state.counterPresta + 1;
        var pageNumberAllegro = this.state.counterAllegro + 1;
        var pageNumber;
        if(type == "raks") pageNumber = pageNumberRaks;
        if(type == "presta") pageNumber = pageNumberPresta;
        if(type == "allegro") pageNumber = pageNumberAllegro;

        return (
            <Row size="md">
                <Form.Group as={Col} controlId="formGridBack">
                    <Button className="pagination-btn"
                        onClick={this.decrement.bind(this, type)}>
                        &#8592; Poprzednia strona 
                    </Button>
                </Form.Group>
                <Form.Group as={Col}>
                    <h6 className="pagination-main" style={{marginTop: "8px"}}>Strona {pageNumber}.</h6>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridNext">
                    <Button className="pagination-btn" 
                        onClick={this.increment.bind(this, type)}>
                        Następna strona &nbsp;&#8594;
                    </Button>
                </Form.Group>
            </Row>
        )
    }
    /* Bar chart */
    chart(){
        var countryList = this.state.dataCountry.reverse().map((e)=>(e.country));
        return (
            <XYPlot minWidth={500} width={500} height={countryList.length * 100 + 20}
                colorType="category" 
                color='red'
            >
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis 
                    tickFormat={v => countryList[v]}
                />
                <HorizontalBarSeries
                    data={this.state.dataCountry.map((e, index)=>({
                        y: index,
                        x: Number(e.sales),
                        style: {background: 'red', overflow: "hidden"}
                    }))}
                />
            </XYPlot>
        )
    }
    /* Main order Tables */
    renderTable(type){
        var title
        if(type=="raks") title = "Sprzedaż hurtowa / dział handlowy"
        if(type=="presta") title = "Detal"
        if(type=="allegro") title = "Allegro"

        return(
            <>
                <Row>
                    <div className="content">
                        <div className="container">
                            <h4>{title}</h4><br/>
                            <div className="table-responsive">
                                <table className="table table-striped custom-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nr.</th>
                                            {type == "allegro" ? null
                                            : <th scope="col">Status</th> }
                                            <th scope="col">Data</th>
                                            <th scope="col">Nazwa produktu</th>
                                            <th scope="col">Indeks</th>
                                            <th scope="col">Ilość</th>
                                            <th scope="col">Cena</th>
                                            {type == "allegro" ? null
                                            : <th scope="col">Suma</th> }
                                            {type == "allegro" ? null
                                            : <th scope="col">Klient</th> }
                                            {type == "allegro" ? null
                                            : <th scope="col">Kraj</th> }
                                            <th scope="col">Suma ost.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderRows(type)}
                                    </tbody>
                                </table>
                            </div>
                            {this.renderButtons(type)}
                        </div>
                    </div>
                </Row>
                <br/><br/>
            </>
        )
    }
    /* Render rows to order tables */
    renderRows(type){
        var data=[]
        if(type=="raks") data = this.state.dataRaks 
        if(type=="presta") data = this.state.dataPresta
        if(type=="allegro") data = this.state.dataAllegro

        if(data.message == null){
            return(
                data.map((e)=>
                    <tr scope="row">
                        <td>
                            {e.orderNo}
                        </td>
                        {type == "allegro" 
                        ? null : 
                            <td>
                                {e.status}
                            </td> 
                        }
                        <td>
                            {e.date.slice(0,10)}
                        </td>
                        <td>
                            {e.productName}
                        </td>
                        <td>
                            {e.index}
                        </td>
                        <td>
                            {e.quantity}
                        </td>
                        <td>
                            {e.pricePerProduct} zł
                        </td>
                        {type == "allegro" 
                        ? null : 
                            <td>
                                {e.priceTotal} zł
                            </td> 
                        }
                        {type == "allegro" 
                        ? null : 
                            <td>
                                {e.clientName}
                            </td> 
                        }
                        {type == "allegro" 
                        ? null : 
                            <td>
                                {e.country}
                            </td> 
                        }
                        <td>
                            {e.priceTotal} zł
                        </td>
                    </tr>
                )
            )
        }
        else{
            return(
                <tr scope="row">
                    <td>
                        Brak danych
                    </td>
                </tr>
            )
        }
    }
    /* Render Country table */
    renderTableCountry(){
        return(
            <Row>
                <div className="content">
                    <div className="">
                        <h4 className='right-title-main'>Sprzedaż na kraj</h4><br/>
                        <div className="">
                            <table className="table table-striped custom-table custom-table2">
                                <thead>
                                
                                    <tr>
                                        <th scope="col">Kraj</th>
                                        <th scope="col">Suma</th>
                                        <th scope="col">%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRowCountry()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Row>
        )
    }    
    /* Render chart row structure */
    renderRowChart(){
        return(
            <Row>
                <div className="content">
                    <h4 className='right-title-main'>Wykres sprzedaży</h4><br/>
                    <div className="bar-main-sales">
                        <div className="">
                            {this.chart()}
                        </div>
                    </div>
                </div>
            </Row>
        )
    }
    /* Render rows to sales per country table */
    renderRowCountry(){
        var dataCountry = this.state.dataCountry
        if(dataCountry.message == null){
            return(
                dataCountry.map((country)=>
                    <tr scope="row">
                        <td>
                            {country.country}
                        </td>
                        <td>
                            {country.sales} zł
                        </td>
                        <td>
                            {country.percent}
                        </td>
                    </tr>
                )
            )
        }
        else{
            return(
                <tr scope="row">
                    <td>
                        Brak danych
                    </td>
                </tr>
            ) 
        }
    }
    /* Render content on page */
    content(){
        var type;
        try{
            return(
                <div className="container-main col-xs-12 col-lg-12 col-md-12 col-sm-12">
                    <Row>
                        <h2>TOTAL</h2><br/>
                    </Row>
                    <Col className="row-main col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        {this.renderTable('raks')}
                        {this.renderTable('presta')}
                        {this.renderTable('allegro')}
                    </Col>
                    <Col className="row-main col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        {this.renderTableCountry()}
                        <br/><br/>
                        {this.renderRowChart()}
                    </Col>
                </div>
            )
        }
        catch(ex){
            console.log(ex)
            return(
                <h1>
                    Nie znalezniono danych dla tego miesiąca.
                </h1>
            )
        }
    }       
    /* Main function */
    render(){
        if(localStorage.getItem("JWT-token") == null ||
           localStorage.getItem("JWT-token") == undefined ||
           localStorage.getItem("month-date") == null ||
           localStorage.getItem("month-date") == undefined ||
           localStorage.getItem("month-date") == '')
           {
                return window.location = '/wybierz-date'
           }
        else{
            return(
                <>
                    <Bar1/>
                    {this.content()}
                </>
            )
        }
    }
}
export default Home;