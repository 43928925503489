import soft99Logo from '../styles/img/soft99_logo.png';

export function FunctionLogo(){
    var img = soft99Logo;
    return(    
        <div className='main-logo'>
            <a href="/strona-glowna" className='no-a-href-hover title-szkolenia-main'><img src={img} width={60}/> Analiza generalna</a>
        </div>
    );
}

export function FunctionLogoNoHref(){
    var img = soft99Logo;
    return(    
        <div className='main-logo'>
            <span className='no-a-href-hover title-szkolenia-main'><img src={img} width={60}/> Analiza generalna</span>
        </div>
    );
}